import React, { type ReactElement, useState } from "react";
import ReactDOM from "react-dom";
import { IoClose } from "react-icons/io5";
import { MdMessage } from "react-icons/md";

import Loader from "@components/loader";
import { useAuthenticationState } from "@stores/authentication";
import { useLiveChatState } from "@stores/live_chat";
import { cn, defaultRipple } from "@utilities/helper";
import { AnimatePresence, motion } from "framer-motion";

const LiveChatPopup = (): ReactElement => {
  const isSSR =
    typeof document === "undefined" && typeof window === "undefined";
  const authenticationState = useAuthenticationState();
  const liveChatState = useLiveChatState();

  const getUrl = (): string => {
    if (authenticationState.isAuthenticated()) {
      const url = "https://my.bnesim.com/support-board.php";
      const params = new URLSearchParams({
        hide_close: "1",
        t: authenticationState.getToken(),
      });
      return `${url}?${params.toString()}`;
    }
    return "";
  };

  const toggleLiveChatPopup = (): void => {
    setIsIframeLoaded(false);
    liveChatState.setIsOpenPopup(!liveChatState.isOpenPopup);
  };

  const isShowLiveChatPopup = (): boolean => {
    return authenticationState.isAuthenticated();
  };

  const [isIframeLoaded, setIsIframeLoaded] = useState<boolean>(false);
  const handleIframeLoad = (): void => {
    setIsIframeLoaded(true);
  };

  return (
    <>
      {!isSSR &&
        ReactDOM.createPortal(
          <AnimatePresence>
            {isShowLiveChatPopup() && (
              <div className="container pointer-events-none fixed inset-0 z-20 h-dvh w-screen">
                <motion.button
                  ref={defaultRipple}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  onClick={toggleLiveChatPopup}
                  className={cn(
                    "focus:custom-primary-focus-ring pointer pointer-events-auto absolute bottom-24 right-6 size-12 rounded-full border border-primary bg-primary p-2 text-white shadow-md outline-none transition duration-300 hover:!opacity-80",
                    {
                      "-rotate-90 hover:rotate-0": liveChatState.isOpenPopup,
                    },
                  )}
                >
                  {!liveChatState.isOpenPopup ? (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <MdMessage className="size-full" />
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <IoClose className="size-full" />
                    </motion.span>
                  )}
                </motion.button>
                <AnimatePresence>
                  {liveChatState.isOpenPopup && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0 }}
                      transition={{ duration: 0.3 }}
                      className="pointer-events-auto absolute bottom-40 right-6 h-[60dvh] w-[calc(100%-3rem)] max-w-[464px] origin-bottom-right overflow-hidden rounded-2xl border-2 border-secondary bg-white shadow-md"
                    >
                      {!isIframeLoaded && (
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className="!absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                        >
                          <Loader />
                        </motion.span>
                      )}
                      <div className="relative size-full">
                        <iframe
                          src={getUrl()}
                          onLoad={handleIframeLoad}
                          className="size-full"
                        ></iframe>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
          </AnimatePresence>,
          document.body,
        )}
    </>
  );
};

export default LiveChatPopup;
