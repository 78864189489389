import { useI18next } from "gatsby-plugin-react-i18next";

import { useRipple } from "@chaocore/ripple";
import CookieType from "@constants/CookieType";
import type Product from "@interfaces/Product";
import tailwindConfig from "@root/tailwind.config";
import { useAccountDetailStore } from "@stores/account_detail";
import { useAuthenticationStore } from "@stores/authentication";
import { useConfigurationStore } from "@stores/configuration";
import { usePaymentStore } from "@stores/payment";
import { type ClassValue, clsx } from "clsx";
import Cookies from "js-cookie";
import { twMerge } from "tailwind-merge";
import resolveConfig from "tailwindcss/resolveConfig";

export const cn = (...inputs: ClassValue[]): string => {
  return twMerge(clsx(inputs));
};

export const isDebugMode = (): boolean => process.env.GATSBY_DEBUG === "true";

export const defaultRipple = useRipple({});

export const primaryRipple = useRipple({
  color: resolveConfig(tailwindConfig).theme.colors.primary.DEFAULT,
});

export const getDurationText = (product: Product): string => {
  const { t } = useI18next();
  let durationText = "";
  switch (product.duration) {
    case -1:
      durationText = t("no_expiration");
      break;
    case 7:
    case 30:
      if (product.has_subscription) {
        durationText = t("monthly");
      } else {
        durationText = product.duration + " " + t("days");
      }
      break;
  }
  return durationText;
};

export const handleRedirectToApp = (): void => {
  if (typeof window !== "undefined" && window.location.href) {
    const appUrl = process.env.GATSBY_APP_URL ?? "";

    let fallbackUrl;
    if (/android/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_PLAY_STORE_LINK ?? "/";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      fallbackUrl = process.env.GATSBY_APP_STORE_LINK ?? "/";
    } else {
      fallbackUrl = process.env.GATSBY_SITE_URL ?? "/";
    }

    window.location.href = appUrl;

    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 3000);
  }
};

export const clearUserData = (): void => {
  useAccountDetailStore.getState().clear();
  useAuthenticationStore.getState().clear();
  usePaymentStore.getState().clear();
  useConfigurationStore.getState().reset();
  Cookies.remove(CookieType.CURRENCY, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.TOKEN, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.ACCOUNT_NUMBER, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.HASHED_PASSWORD, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
  Cookies.remove(CookieType.AFFILIATE, {
    domain: process.env.GATSBY_ROOT_DOMAIN,
  });
};

export const range = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);
