import CookieType from "@constants/CookieType";
import SocialProvider from "@constants/SocialProvider";
import { useConfigurationStore } from "@stores/configuration";
import axios from "@utilities/axios";
import { isDebugMode } from "@utilities/helper";
import Cookies from "js-cookie";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface AuthenticationState {
  token: string;
  account_number: string;
  hashed_password: string;
  register: (
    fullName: string,
    email: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
    alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
  ) => Promise<void>;
  login: (
    phoneNumber: number | string,
    password: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  reloadLogin: (
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  loginWithLicenseToken: (
    licenseToken: string,
    callback?: () => void,
    successCallback?: (response: unknown) => void,
    errorCallback?: (error: unknown) => void,
  ) => Promise<void>;
  getToken: () => string;
  setToken: (value: string) => void;
  isAuthenticated: () => boolean;
  clear: () => void;
}

export const useAuthenticationStore = create<AuthenticationState>()(
  persist(
    (set, get) => ({
      token: "",
      account_number: "",
      hashed_password: "",
      register: async (
        fullName: string,
        email: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
        alreadyHaveAccountCallback?: (socialAccounts: SocialProvider[]) => void,
      ) => {
        const voucherCode = (
          useConfigurationStore.getState().affiliate ?? "site"
        ).toUpperCase();

        const searchLicenseParams = new URLSearchParams({
          action: "search-licenses",
          contact_email: email,
        });
        const searchLicenseRequest = `/sim_card_activation/?${searchLicenseParams.toString()}`;
        axios
          .get(searchLicenseRequest)
          .then(response => {
            if (
              response.data.social_to_propose &&
              Object.values(SocialProvider).includes(
                response.data.social_to_propose as SocialProvider,
              )
            ) {
              if (alreadyHaveAccountCallback) {
                if (callback) {
                  callback();
                }
                alreadyHaveAccountCallback([response.data.social_to_propose]);
              }
            } else {
              activate();
            }
          })
          .catch(error => {
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {});

        const activate = (): void => {
          const activateParams = new URLSearchParams({
            is_app_registration: "1",
            from: "esim",
            fullname: fullName,
            contact_email: email,
            "voucher-code": voucherCode,
          });
          const activateRequest = `/sim_card_activation/?${activateParams.toString()}`;

          axios
            .get(activateRequest)
            .then(response => {
              const params = new URLSearchParams({
                is_app_registration: "1",
                "voucher-code": voucherCode,
                "transaction-id": response.data.message,
                contact_email: email,
              });
              const checkRequest = `/sim_card_activation_status/?${params.toString()}`;
              startCheckingAccountInterval(checkRequest);
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            })
            .finally(() => {});
        };

        let interval: NodeJS.Timeout;
        let isRegisterSuccessful = false;
        const startCheckingAccountInterval = (request: string): void => {
          interval = setInterval(() => {
            checkActivationStatus(request);
          }, 5000);
          setTimeout(() => {
            if (!isRegisterSuccessful) {
              if (isDebugMode()) {
                console.log("signing up timeout");
              }
              clearInterval(interval);
              if (errorCallback) {
                errorCallback({
                  message:
                    "An expected error occurred during account creation. Please try again or contact support.",
                });
              }
              if (callback) {
                callback();
              }
            }
          }, 30000);
        };

        const checkActivationStatus = (request: string): void => {
          axios
            .get(request)
            .then(response => {
              if (isDebugMode()) {
                console.log("Request success : ", response.data);

                console.log("Request success result: " + response.data.message);
              }

              if (response.data.message === "2") {
                clearInterval(interval);
                isRegisterSuccessful = true;

                const licenseToken =
                  response.data.username + ":" + response.data.hashed_password;

                get().loginWithLicenseToken(
                  licenseToken,
                  () => {
                    if (callback) {
                      callback();
                    }
                  },
                  response => {
                    if (successCallback) {
                      successCallback(response);
                    }
                  },
                  error => {
                    if (errorCallback) {
                      errorCallback(error);
                    }
                  },
                );
              }
            })
            .catch(error => {
              if (errorCallback) {
                errorCallback(error);
              }
            });
        };
      },
      login: async (
        phoneNumber: number | string,
        password: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        let isCallbackAlreadyCalled = false;
        let isErrorCallbackAlreadyCalled = false;
        const params = new URLSearchParams({
          format: "json",
          cloud_username: phoneNumber.toString(),
          cloud_password: password,
        });
        const autoProvisionRequest = `/autoprovision/?${params.toString()}`;

        axios
          .get(autoProvisionRequest)
          .then(response => {
            if (isDebugMode()) {
              console.log("JSON response:", response.data);
            }
            const licenseToken =
              response.data.cloud_username +
              ":" +
              response.data.hashed_password;
            get().loginWithLicenseToken(
              licenseToken,
              () => {
                if (callback && !isCallbackAlreadyCalled) {
                  callback();
                  isCallbackAlreadyCalled = true;
                }
              },
              response => {
                if (successCallback) {
                  successCallback(response);
                }
              },
              error => {
                if (errorCallback && !isErrorCallbackAlreadyCalled) {
                  errorCallback(error);
                  isErrorCallbackAlreadyCalled = true;
                }
              },
            );
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback && !isErrorCallbackAlreadyCalled) {
              errorCallback(error);
              isErrorCallbackAlreadyCalled = true;
            }
          })
          .finally(() => {
            if (callback && !isCallbackAlreadyCalled) {
              callback();
              isCallbackAlreadyCalled = true;
            }
          });
      },
      reloadLogin: async (
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const accountNumberCookie = Cookies.get(CookieType.ACCOUNT_NUMBER);
        const accountNumberStored = get().account_number;
        const hashedPasswordCookie = Cookies.get(CookieType.HASHED_PASSWORD);
        const hashedPasswordStored = get().hashed_password;

        let account_number = "";
        let hashed_password = "";
        if (
          accountNumberCookie &&
          accountNumberCookie === accountNumberStored
        ) {
          account_number = accountNumberCookie;
        }
        if (
          hashedPasswordCookie &&
          hashedPasswordCookie === hashedPasswordStored
        ) {
          hashed_password = hashedPasswordCookie;
        }

        if (!account_number || !hashed_password) {
          if (errorCallback) {
            errorCallback(null);
            return;
          }
        }
        const licenseToken = account_number + ":" + hashed_password;
        get().loginWithLicenseToken(
          licenseToken,
          callback,
          successCallback,
          errorCallback,
        );
      },
      loginWithLicenseToken: async (
        licenseToken: string,
        callback?: () => void,
        successCallback?: (response: unknown) => void,
        errorCallback?: (error: unknown) => void,
      ) => {
        const params = new URLSearchParams({
          license_token: licenseToken,
        });
        const loginRequest = `/login?${params.toString()}`;
        if (isDebugMode()) {
          console.log("loginRequest:", loginRequest);
        }
        axios
          .get(loginRequest)
          .then(response => {
            const loginCredentials = licenseToken.split(":");
            const accountNumber = loginCredentials[0];
            const hashedPassword = loginCredentials[1];
            set({
              token: response.data.token,
              account_number: accountNumber,
              hashed_password: hashedPassword,
            });
            Cookies.set(CookieType.TOKEN, response.data.token, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            Cookies.set(CookieType.ACCOUNT_NUMBER, accountNumber, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            Cookies.set(CookieType.HASHED_PASSWORD, hashedPassword, {
              domain: process.env.GATSBY_ROOT_DOMAIN,
            });
            if (successCallback) {
              successCallback(response);
            }
          })
          .catch(error => {
            if (error.response) {
              console.error(`Response status: ${error.response.status}`);
            } else {
              console.error(error.message);
            }
            if (errorCallback) {
              errorCallback(error);
            }
          })
          .finally(() => {
            if (callback) {
              callback();
            }
          });
      },
      getToken: () => {
        return get().token;
      },
      setToken: (value: string) => {
        set({
          token: value,
        });
      },
      isAuthenticated: () => {
        return !!get().token;
      },
      clear: () => {
        set({
          token: "",
          account_number: "",
          hashed_password: "",
        });
      },
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useAuthenticationState = (): AuthenticationState => {
  return useAuthenticationStore(state => {
    return state;
  });
};
