import { create } from "zustand";

export interface LiveChatState {
  isOpenPopup: boolean;
  setIsOpenPopup: (value: boolean) => void;
}

export const useLiveChatStore = create<LiveChatState>(set => ({
  isOpenPopup: false,
  setIsOpenPopup: (value: boolean) => {
    set({
      isOpenPopup: value,
    });
  },
}));

export const useLiveChatState = (): LiveChatState => {
  return useLiveChatStore(state => {
    return state;
  });
};
