import React, { type ReactElement } from "react";
import { type WrapPageElementNodeArgs } from "gatsby";

import Layout from "@components/layout";
import Loader from "@components/loader";

const wrapPageElement = ({
  element,
  props,
}: WrapPageElementNodeArgs): ReactElement => {
  if (element?.props?.children?.props?.children) {
    const newElement = React.cloneElement(
      element, // I18nextProvider
      element.props,
      React.cloneElement(
        element.props.children, // I18nextContext.Provider
        element.props.children.props,
        React.createElement(
          Layout,
          undefined,
          element.props.children.props.children,
        ),
      ),
    );
    return newElement;
  } else {
    if (typeof window !== "undefined") {
      return (
        <Layout showHeader={false} {...props}>
          <div className="inset-0 flex h-dvh w-full items-center justify-center">
            <Loader />
          </div>
        </Layout>
      );
    }
    return <></>;
  }
};

export default wrapPageElement;
