import React, { type FC, type ReactElement, useState } from "react";
import { FaBars, FaUser } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { LiaCheckCircle } from "react-icons/lia";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

import LangIcon from "@components/icons/language_icon";
import LogoutModal from "@components/logout_modal";
import Modal from "@components/modal";
import CookieType from "@constants/CookieType";
import LanguagesData from "@data/languages.json";
import MenuData from "@data/mobile-menu.yml";
import type Language from "@interfaces/Language";
import type MobileMenu from "@interfaces/MobileMenu";
import { useAccountDetailState } from "@stores/account_detail";
import { useAuthenticationState } from "@stores/authentication";
import { cn, primaryRipple } from "@utilities/helper";
import Cookies from "js-cookie";

const Header = (): ReactElement => {
  const accountDetailState = useAccountDetailState();
  const authenticationState = useAuthenticationState();

  const { t, changeLanguage, language } = useI18next();

  const [langModalOpen, setLangModalOpen] = useState<boolean>(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);

  const profileImageLink = MenuData.find(
    (menu: MobileMenu) => menu.label === "profile",
  )?.link;

  const getGreeting = (): string => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return t("greeting_0");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("greeting_1");
    } else {
      return t("greeting_2");
    }
  };

  const updateLang = (lang: string): void => {
    changeLanguage(lang);
    Cookies.set(CookieType.LANG, lang, {
      domain: process.env.GATSBY_ROOT_DOMAIN,
    });
    setLangModalOpen(false);
  };

  const MoreServiceLink: FC<{ className?: string }> = ({ className }) => {
    return (
      <div className="custom-ripple-container" ref={primaryRipple}>
        <OutboundLink
          className={cn(
            "custom-link-btn-primary items-center gap-2 md:px-4 md:py-2",
            className,
          )}
          href={process.env.GATSBY_MAIN_SITE_URL}
          target="_blank"
        >
          <IoIosAddCircle className="size-5 md:size-7" />
          <span className="text-nowrap text-primary">{t("more_services")}</span>
        </OutboundLink>
      </div>
    );
  };

  const AuthLink: FC<{
    type: "both" | "login" | "register";
  }> = ({ type = "both" }): ReactElement => {
    switch (type) {
      case "login":
        return (
          <Link
            ref={primaryRipple}
            className="custom-link-btn-primary flex cursor-pointer items-center gap-2 justify-self-center"
            to="/login"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <FaUser className="h-5 w-4 text-primary" />
            <span className="text-sm sm:text-base">
              {t("signup") + "/" + t("login_title")}
            </span>
          </Link>
        );
      case "register":
        return (
          <Link
            ref={primaryRipple}
            className="custom-link-btn-primary flex cursor-pointer items-center gap-2 justify-self-center"
            to="/register"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <FaUser className="h-5 w-4 text-primary" />
            <span className="text-sm sm:text-base">
              {t("signup") + "/" + t("login_title")}
            </span>
          </Link>
        );
      case "both":
      default:
        return (
          <div
            ref={primaryRipple}
            className="flex rounded-full px-4 py-2 transition duration-300 hover:bg-primary-100"
          >
            <Link
              className="flex cursor-pointer gap-2 justify-self-end text-primary no-underline focus:outline-none md:items-center"
              to="/register"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <FaUser className="h-5 w-4 text-primary" />
              <span className="text-sm sm:text-base">{t("signup") + "/"}</span>
            </Link>
            <Link
              className="flex gap-2 justify-self-start text-primary no-underline focus:outline-none"
              to="/login"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <span className="text-sm sm:text-base">{t("login_title")}</span>
            </Link>
          </div>
        );
    }
  };

  return (
    <header className="fixed inset-x-0 top-0 z-20 flex w-screen justify-center border-b-2 border-gray-300 bg-white">
      <nav className="container flex min-h-[75px] w-full items-center justify-between">
        <div className="flex items-center justify-between md:justify-start">
          {authenticationState.isAuthenticated() ? (
            <OutboundLink
              href={profileImageLink}
              className="group mr-2 h-fit pr-2 text-primary-100 no-underline transition duration-300 hover:opacity-80 focus:outline-none md:border-r-2 md:border-solid md:border-primary-200 md:pr-6"
            >
              <div className="flex flex-row items-center gap-2">
                {/* {accountDetailState.profile.image ? (
                  <img
                    src={accountDetailState.profile.image}
                    alt="Profile"
                    className="size-8 rounded-full md:size-12"
                  />
                ) : (
                  <FaUserCircle className="size-8 text-primary md:size-12" />
                )} */}
                <FaBars className="size-6 text-black transition duration-300 group-hover:text-primary" />
                <div className="flex flex-col justify-start">
                  <span className="text-nowrap text-base font-semibold text-black transition duration-300 group-hover:text-primary md:text-lg">
                    {t("hi") + " " + accountDetailState.license.name}
                  </span>
                  <span className="text-nowrap text-sm text-black opacity-80 transition duration-300 group-hover:text-primary md:text-base">
                    {getGreeting()}
                  </span>
                </div>
              </div>
            </OutboundLink>
          ) : (
            <>
              <OutboundLink
                href={process.env.GATSBY_MAIN_SITE_URL}
                className="h-fit pr-1 text-primary-100 transition duration-300 hover:opacity-80 focus:outline-none sm:pr-2 md:mr-2 md:border-r-2 md:border-solid md:border-primary-200 md:pr-6"
              >
                <StaticImage
                  src="../assets/images/logo.png"
                  placeholder="none"
                  className="w-[80px] md:w-[100px]"
                  loading="eager"
                  width={100}
                  height={33}
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Site logo"
                />
              </OutboundLink>
              {/* <MoreServiceLink className="hidden md:flex" /> */}
            </>
          )}
        </div>
        <div className="flex items-center gap-1 sm:gap-2 md:gap-4">
          {!authenticationState.isAuthenticated() && (
            <AuthLink type="register" />
          )}

          {/* {!authenticationState.isAuthenticated() && (
            <MoreServiceLink className="flex md:hidden" />
          )} */}
          <div>
            <button
              ref={primaryRipple}
              className="custom-link-btn-primary flex items-center gap-2 justify-self-end md:px-4"
              onClick={() => {
                setLangModalOpen(true);
              }}
            >
              <LangIcon />
              <span className="text-sm sm:text-base">
                {language.toUpperCase()}
              </span>
            </button>
            <Modal
              isModalOpen={langModalOpen}
              setIsModalOpen={setLangModalOpen}
              isCloseButtonExist={true}
              bgColor={"#f3f3f3"}
              title={t("language") ?? ""}
              isContentScrollable={true}
            >
              <div className="flex flex-col gap-3">
                {LanguagesData.map((lang: Language, i: number) => {
                  return (
                    <button
                      ref={primaryRipple}
                      key={lang.name}
                      onClick={() => {
                        updateLang(lang.id);
                      }}
                      className={cn(
                        "space-between focus:custom-primary-focus-ring group flex w-full items-center rounded-3xl !border-2 border-solid border-primary-200 p-3 shadow-lg shadow-gray-300 transition duration-300",
                        {
                          "bg-primary": lang.id === language,
                          "bg-white hover:opacity-80": lang.id !== language,
                        },
                      )}
                    >
                      <span
                        className={cn(
                          "block w-full no-underline transition duration-300",
                          {
                            "text-white": lang.id === language,
                            "text-black group-hover:text-primary":
                              lang.id !== language,
                          },
                        )}
                      >
                        {lang.name}
                      </span>
                      <LiaCheckCircle className="size-5 text-white" />
                    </button>
                  );
                })}
              </div>
            </Modal>
          </div>
        </div>
      </nav>
      <LogoutModal
        isModalOpen={logoutModalOpen}
        setIsModalOpen={setLogoutModalOpen}
      />
    </header>
  );
};

export default Header;
